.small-block-links {
  margin: 40px 0;

  .col-item {
    margin-bottom: 22px;
  }

  .center-image-card {
    display: flex;
    overflow: hidden;
    height: 100px;
    align-items: center;
    justify-content: center;
    border-right: none;
    border: 8px solid $white;
    background: $white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    .img-link svg {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 2;
    }

    img,
    .img-skeleton {
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }

    .img-skeleton {
      height: 80px;
    }
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      height: unset;
    }
  }

  .title {
    margin-bottom: 60px;
  }
}
