/***
******************+++
Questo file definisce alcune variabili aggiuntive per design-comuni-plone-theme
******************+++
**/

//cms
$placeholder: #b8c6c9 !default;

//site
$primary-text: #fff !default;
$secondary-text: #fff !default;
$tertiary: #be2816 !default;
$tertiary-text: #fff !default;
$highlight-search: #ff0;
$font-family-monospace-light: Roboto Mono Light;
$gdpr-accept-all: #005700 !default;
$gdpr-toggle-checked: #005700 !default;
$gdpr-toggle: #b22515 !default;
$gdpr-toggle-border: #000 !default;
$gdpr-focus-color: #ff9800 !default;
$gdpr-link-color: #004285 !default;
$gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
  0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;

$argument-icon-color: $tertiary !default;
$argument-icon-bg: #f5f6f7 !default;
$external-link-fill-buttons: $tertiary-text !default;
$external-link-fill-subfooter: $tertiary-text !default;

// $dvt-header-center-max-height: 166px;
// $header-center-max-height-mob: 80px;

$dvt-navigation-v-padding: 15px;
// $navigation-h-padding: 24px;
