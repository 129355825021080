.gdpr-privacy-banner {
  z-index: 9999;

  .close-button {
    color: #000;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff !important;
    }
  }

  .gdpr-privacy-content-wrapper {
    padding: 2rem 0;

    .gdpr-privacy-settings {
      .settings-column {
        .settings-title {
          padding-bottom: 0.5rem;

          .ui.checkbox.toggle label {
            display: flex;
            align-items: center;
            padding-right: 4.5rem;
            margin-bottom: 0;
            line-height: 1.2rem;
          }
        }

        .ui.checkbox {
          position: relative;
          display: inline-block;
          min-width: 17px;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          font-size: 1rem;
          font-style: normal;
          line-height: 17px;
          outline: none;
          vertical-align: baseline;
          input:focus ~ label::before {
            box-shadow: $gdpr-focus-shadow !important;
            outline: none !important;
          }

          label {
            position: relative;
            display: block;
            outline: none;
            transition: color 0.1s ease;

            &:before {
              left: 0;
              border: 1px solid $gdpr-toggle-border;
              background-color: $gdpr-toggle;
              transition: border 0.1s ease, opacity 0.1s ease,
                transform 0.1s ease, box-shadow 0.1s ease;
            }

            &:after {
              color: rgba(0, 0, 0, 0.87);
              text-align: center;
            }
          }

          input[type='checkbox'] {
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: pointer;
            opacity: 0 !important;
            outline: none;
          }

          input:indeterminate ~ label:after {
            font-size: 0.85714286rem;
          }

          input[disabled] ~ label {
            cursor: default !important;
          }

          input.hidden + label {
            cursor: pointer;
            user-select: none;
          }

          &.toggle {
            min-height: 1.5rem;

            input {
              width: 3.5rem;
              height: 1.5rem;

              ~ label:after {
                left: -0.05rem;
                box-shadow: none;
              }

              &.hidden {
                z-index: -1;
              }

              &:checked {
                ~ label {
                  &:before {
                    background-color: $gdpr-toggle-checked;
                  }

                  &:after {
                    left: 2.15rem;
                    box-shadow: none;
                  }
                }
              }
            }

            label {
              min-height: 1.5rem;
              padding-top: 0.15em;
              padding-right: 3.5rem;
              color: rgba(0, 0, 0, 0.87);

              &:before {
                position: absolute;
                z-index: 1;
                top: 0rem;
                display: block;
                width: 3.5rem;
                height: 1.5rem;
                border: 1px solid $gdpr-toggle-border;
                border-radius: 500rem;
                content: '';
                transform: none;
              }

              &:after {
                position: absolute;
                z-index: 2;
                top: 0rem;
                width: 1.5rem;
                height: 1.5rem;
                border: 1.5px solid $gdpr-toggle-border;
                background: #ffffff
                  linear-gradient(transparent, rgba(0, 0, 0, 0.05));
                border-radius: 500rem;
                content: '' !important;
                opacity: 1;
                transition: background 0.3s ease, left 0.3s ease;
              }
            }
          }

          &.fitted {
            width: 3.5rem;

            label {
              padding-left: 0em !important;
            }
          }
        }

        .ui.checkbox {
          &.toggle {
            input {
              width: 4.5rem;
              height: 2rem;

              &:focus {
                & + label {
                  &:before {
                    box-shadow: 0 0 0 5px black !important;
                    outline: #ddd 2px solid !important;
                  }
                }
              }
            }
          }
        }
      }

      .choices .choice-title .ui.toggle.checkbox label {
        margin-bottom: 0;
      }
    }
  }
}

button.gdpr-privacy-show-banner {
  margin: 0;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

body.has-toolbar {
  .gdpr-privacy-banner {
    width: calc(100vw - 80px) !important;
  }
}

body.has-toolbar-collapsed {
  .gdpr-privacy-banner {
    width: calc(100vw - 20px) !important;
  }
}

@media (max-width: #{map-get($grid-breakpoints, md)}) {
  .gdpr-privacy-banner {
    .gdpr-privacy-content-wrapper {
      padding: 2rem 1rem 4rem 1rem;

      .gdpr-privacy-content {
        .buttons {
          display: flex;
          flex-direction: column;

          button.gdpr-privacy-banner-button {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.gdpr-privacy-banner
  .gdpr-privacy-content-wrapper
  .gdpr-privacy-content
  .buttons
  button.gdpr-privacy-banner-button:focus,
.gdpr-privacy-banner
  .gdpr-privacy-content-wrapper
  .gdpr-privacy-content
  a:focus {
  box-shadow: $gdpr-focus-shadow;
  outline: none !important;
}

.gdpr-privacy-banner
  .gdpr-privacy-content-wrapper
  .gdpr-privacy-content
  .buttons
  button.gdpr-privacy-banner-button.btn-primary {
  background-color: $gdpr-accept-all;
}
