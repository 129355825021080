.page-sharing,
.page-actions {
  .btn-dropdown:focus {
    border-color: $focus-outline-color;
    box-shadow: 0 0 0 2px $focus-outline-color;
    outline: none;
  }

  .link-list-wrapper {
    position: relative;
    z-index: 10;

    ul {
      li {
        a.list-item,
        button.btn-link {
          display: flex;
          align-items: center;

          .icon {
            width: 28px;
            height: 28px;
            margin-right: 0.5em;
          }
        }

        button.btn-link {
          width: 100%;
          border-radius: 0;

          &:focus {
            border-color: $focus-outline-color;
            box-shadow: 0 0 0 2px $focus-outline-color;
            outline: none;
          }
        }
      }
    }
  }
}
