.complete-block-links-template {
  margin: 40px 0;

  a {
    text-decoration: none;
  }

  .col-item {
    margin-bottom: 22px;
  }

  .image-container {
    display: flex;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin: 18px 0px 0px 18px;

    background-color: $white;
    border-radius: 50%;
    overflow: hidden;

    .volto-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .card {
    a {
      color: $secondary-text;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &.card-bg {
      &.secondary {
        background-color: $secondary;
      }
      &.primary {
        background-color: $primary;
      }
      &.transparent {
        .text-secondary {
          color: $body-color !important;
        }
        a {
          color: $body-color;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .card-text {
    font-family: $font-family-sans-serif !important;
  }

  h3.card-title {
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 1.44444rem;
    a {
      color: $secondary-text !important;
    }
    .external-link {
      fill: $external-link-fill-buttons !important;
    }
  }

  .card:after {
    content: none;
  }
  .no-external-if-link > .external-link {
    display: none;
  }
}
