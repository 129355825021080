.public-ui {
  button.btn,
  button.rounded-right {
    &:focus {
      border-color: $focus-outline-color !important;
      box-shadow: inset 0 1px 0 $focus-outline-color,
        0 1px 1px $focus-outline-color, 0 0 0 0.2rem $focus-outline-color !important;
      outline: none;
    }
  }
  .btn-tertiary {
    @include button-variant($tertiary, $tertiary);

    svg {
      fill: $tertiary-text;
    }

    &:hover,
    &:active {
      svg {
        fill: $tertiary-text;
      }
    }
  }

  a.btn-tertiary {
    color: $tertiary-text !important;
    &:focus {
      border-color: $focus-outline-color;
      box-shadow: inset 0 1px 0 $focus-outline-color,
        0 1px 1px $focus-outline-color, 0 0 0 0.2rem $focus-outline-color;
      outline: none;
    }
  }

  .btn-outline-tertiary {
    @include button-outline-variant($tertiary);
  }

  button.chip {
    &:focus {
      outline: none;
    }
  }

  .btn-primary {
    &,
    &:hover,
    &:active {
      svg.icon {
        color: $primary-text;
        fill: $primary-text;
      }
    }
  }
  .center-cards:not(.in-evidence-cards-wrapper) {
    justify-content: space-around !important;
    gap: auto;
    &:after {
      display: none;
    }
  }
  .center-cards.in-evidence-cards-wrapper {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    .card-wrapper {
      &:last-child {
        align-self: center;
      }
    }
  }
  .btn-primary,
  .btn-secondary,
  .btn-tertiary,
  .draftjs-buttons {
    .external-link {
      fill: $external-link-fill-buttons !important;
    }
  }
  .external-link {
    fill: $link-color !important;
    &:hover {
      fill: #004080 !important;
    }
  }
  .it-footer-small-prints-list .external-link {
    fill: $external-link-fill-subfooter !important;
  }
}
