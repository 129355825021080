.public-ui {
  .block.alertblock,
  .alertblock {
    position: relative;
    .bg-alert-warning-orange,
    .bg-alert-warning,
    .bg-alert-danger {
      .draftjs-buttons a {
        background-color: #fff;
        color: $body-color;
        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
    .bg-alert-danger {
      background-color: #a32219;
      color: $white;

      .public-DraftEditorPlaceholder-inner {
        color: $white;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }

      a {
        color: $white;
      }
    }

    .bg-alert-warning-orange {
      background-color: #eb973f;
      color: $black;

      .public-DraftEditorPlaceholder-inner {
        color: $black;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $black;
      }

      a {
        color: $black;
      }
    }

    .bg-alert-warning {
      background-color: #f0c250;
      color: $black;

      .public-DraftEditorPlaceholder-inner {
        color: $black;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $black;
      }

      a {
        color: $black;
      }
    }

    .DraftEditor-root {
      background: none;
    }

    p {
      margin: 0;
    }

    img.left-image {
      object-fit: unset;
      max-width: 80%;
      &.size {
        &-m {
          max-width: 50%;
        }
        &-s {
          max-width: 30%;
        }
      }
    }

    .image-col {
      text-align: center;
    }

    div + h3,
    p + h3,
    h3 + div,
    h3 + p {
      margin-top: 1.33rem;
    }

    div + h2,
    p + h2,
    h2 + div,
    h2 + p {
      margin-top: 1rem;
    }
  }
  .alert-info-dates {
    position: absolute;
    content: '';
    top: -1em;
    .alert-info-text {
      padding: 0 1em;
      background-color: #fff;
      border: 1px solid #000;
      text-align: center;
    }
  }
}
