@import 'site-variables';
@import 'bootstrap-override/bootstrap-italia-site';
@import 'cms-ui';
@import 'mixins';
@import 'extras/blocks';
@import 'extras/sidebar';
@import 'extras/toolbar';
/* @import 'extras/chips';*/
@import 'extras/modals';
@import 'extras/search';
@import 'extras/checkbox';
@import 'extras/add_content_menu';
@import 'extras/forms';
@import 'extras/blocks-chooser';
@import 'extras/video';
@import 'extras/file-widget';
@import 'extras/tables';

/*** ItaliaTheme ***/
@import 'ItaliaTheme/css_variables';
@import 'ItaliaTheme/common';
@import 'ItaliaTheme/main';
@import 'ItaliaTheme/ar';
@import 'ItaliaTheme/home';
@import 'ItaliaTheme/sitemap';
@import 'ItaliaTheme/Addons/customerSatisfaction';
@import 'ItaliaTheme/Addons/volto-gdpr-privacy';
@import 'ItaliaTheme/Components/unauthorized';
@import 'ItaliaTheme/Components/card';
@import 'ItaliaTheme/Components/parentSiteMenu';
@import 'ItaliaTheme/Components/tertiaryMenu';
@import 'ItaliaTheme/Components/subsiteHeader';
@import 'ItaliaTheme/Components/subsiteFooter';
@import 'ItaliaTheme/Components/scrollToTop';
@import 'ItaliaTheme/Components/venue';
@import 'ItaliaTheme/Components/metadata';
@import 'ItaliaTheme/Components/galleryPreview';
@import 'ItaliaTheme/Components/locationsMap';
@import 'ItaliaTheme/Components/diffField';
@import 'ItaliaTheme/Components/sideMenu';
@import 'ItaliaTheme/Blocks/subblocks-edit';
@import 'ItaliaTheme/Blocks/listing';
@import 'ItaliaTheme/Blocks/tableOfContents';
@import 'ItaliaTheme/Blocks/skeleton';
@import 'ItaliaTheme/Components/pageHeader';
@import 'ItaliaTheme/Blocks/common/searchBlockFilters';
@import 'ItaliaTheme/Blocks/calendar';
@import 'ItaliaTheme/Blocks/alert';
@import 'ItaliaTheme/Blocks/info';
@import 'ItaliaTheme/Blocks/gridBlock';
@import 'ItaliaTheme/Blocks/highlitedContent';
@import 'ItaliaTheme/Blocks/iconBlocks';
@import 'ItaliaTheme/Blocks/imageBlock';
@import 'ItaliaTheme/Blocks/searchSections';
@import 'ItaliaTheme/Blocks/cardWithImageAndInEvidence';
@import 'ItaliaTheme/Blocks/cardWithImageTextBlock';
@import 'ItaliaTheme/Blocks/cardWithSlideUpTextTemplate';
@import 'ItaliaTheme/Blocks/smallblockLinkstemplate';
@import 'ItaliaTheme/Blocks/completeBlockLinkstemplate';
@import 'ItaliaTheme/Blocks/squaresImageTemplate';
@import 'ItaliaTheme/Blocks/photogallerytemplate';
@import 'ItaliaTheme/Blocks/sliderTemplate';
@import 'ItaliaTheme/Blocks/inevidencetemplate';
@import 'ItaliaTheme/Blocks/contentInEvidenceTemplate';
@import 'ItaliaTheme/Blocks/mapTemplate';
@import 'ItaliaTheme/Blocks/bandiInEvidenceTemplate';
@import 'ItaliaTheme/Blocks/argumentsInEvidence';
@import 'ItaliaTheme/Blocks/gridGalleryTemplate';
@import 'ItaliaTheme/Blocks/ribbonCardTemplate';
@import 'ItaliaTheme/Blocks/break';
@import 'ItaliaTheme/Blocks/accordion';
@import 'ItaliaTheme/Blocks/simpleCardTemplate';
@import 'ItaliaTheme/Blocks/simpleListTemplate';
@import 'ItaliaTheme/Blocks/ctaBlock';
@import 'ItaliaTheme/Blocks/hero';
@import 'ItaliaTheme/Blocks/contacts';
@import 'ItaliaTheme/Blocks/simpleCardTextBlock';
@import 'ItaliaTheme/Blocks/imageCardTextBlock';
@import 'ItaliaTheme/Blocks/eventSearch';
@import 'ItaliaTheme/Blocks/bandiSearch';
@import 'ItaliaTheme/Blocks/form';
@import 'ItaliaTheme/Blocks/videoGallery';
@import 'ItaliaTheme/Blocks/rssBlock';
@import 'ItaliaTheme/Blocks/numbers';
@import 'ItaliaTheme/Blocks/countdown';
@import 'ItaliaTheme/Blocks/video';
@import 'ItaliaTheme/Blocks/HTML';

@import 'ItaliaTheme/Views/uo';
@import 'ItaliaTheme/Views/evento';
@import 'ItaliaTheme/Views/common';
@import 'ItaliaTheme/Views/bando';
@import 'ItaliaTheme/Views/modules';
@import 'ItaliaTheme/Views/cartellaModulistica';
@import 'ItaliaTheme/Views/trasparenzaView';
@import 'ItaliaTheme/Blocks/simpleCardTemplate';
@import 'ItaliaTheme/Views/paginaArgomentoView';
@import 'ItaliaTheme/Views/sponsors';
@import 'ItaliaTheme/Views/slider';
@import 'ItaliaTheme/Views/faqFolder';
@import 'ItaliaTheme/Views/folder';
@import 'ItaliaTheme/Views/embeddedVideo';
@import 'ItaliaTheme/Views/homepage';
@import 'ItaliaTheme/Widgets/iconWidget';
@import 'ItaliaTheme/Widgets/searchSectionsConfigurationWidget';
@import 'ItaliaTheme/Widgets/blocksWidget';
@import 'ItaliaTheme/Widgets/subsiteSocialLinks';
@import 'ItaliaTheme/Widgets/reactSelect';
@import 'ItaliaTheme/Widgets/react-dates-override';
@import 'ItaliaTheme/Components/megamenu';
@import 'ItaliaTheme/Components/sharing';
@import 'ItaliaTheme/Components/logo';
@import 'ItaliaTheme/Components/mobileMenu';
@import 'ItaliaTheme/Components/relatedItemInEvidence';
@import 'ItaliaTheme/Components/pageLoader';
@import 'ItaliaTheme/Components/navigation';
@import 'ItaliaTheme/Components/search';

@import 'ItaliaTheme/Print/all_pages';
@import 'ItaliaTheme/Print/news_items';
@import 'ItaliaTheme/Print/bandi';
@import 'ItaliaTheme/Print/persona';
@import 'ItaliaTheme/Print/event';
@import 'ItaliaTheme/Print/servizio';
@import 'ItaliaTheme/Print/blocks';
