$primary-hover: darken($primary, 10%);
$secondary-hover: darken($secondary, 10%);
$tertiary-hover: darken($gray-border, 10%);

:root {
  //Variables for future customization
  --info-block-primary-color: #{$primary};
  --info-block-primary-color-hover: #{$primary-hover};
  --info-block-primary-font-color: #{$text-color};
  --info-block-primary-font-color-with-bg: #{$primary-text};
  --info-block-primary-icon-color: var(--info-block-primary-color);

  --info-block-secondary-color: #{$secondary};
  --info-block-secondary-color-hover: #{$secondary-hover};
  --info-block-secondary-font-color: #{$text-color};
  --info-block-secondary-font-color-with-bg: #{$primary-text};
  --info-block-secondary-icon-color: var(--info-block-secondary-color);

  //fixed, doesn't change with the theme
  --info-block-tertiary-color: #{$gray-border};
  --info-block-tertiary-color-hover: #{$tertiary-hover};
  --info-block-tertiary-font-color: #{$black};
  --info-block-tertiary-font-color-with-bg: #{$black};
  --info-block-tertiary-icon-color: var(--info-block-tertiary-color);
}

.public-ui {
  .infoblock {
    .bg-alert-primary,
    .bg-alert-secondary,
    .bg-alert-tertiary {
      border-style: solid;
      border-width: 2px 2px 2px 10px;
      .draftjs-buttons a {
        color: #fff;
      }
      &.bg-color-true {
        .draftjs-buttons a {
          background-color: #fff;
          border: 1px solid transparent;
          color: $text-color;
          span {
            color: $text-color;
          }

          &:hover {
            background-color: $primary;
            border-color: #fff;
            color: #fff;
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .bg-alert-primary {
      color: var(--info-block-primary-font-color);
      border-color: var(--info-block-primary-color);
      .draftjs-buttons a {
        background-color: var(--info-block-primary-color);
        &:hover {
          background-color: var(--info-block-primary-color-hover);
        }
      }
      svg.icon {
        fill: var(--info-block-primary-icon-color);
      }
      &.bg-color-true {
        background-color: var(--info-block-primary-color);
      }
      &.bg-color-true {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        span,
        p,
        .public-DraftEditorPlaceholder-inner {
          color: var(--info-block-primary-font-color-with-bg);
        }
        svg.icon.left-image {
          fill: var(--info-block-primary-font-color-with-bg);
          color: var(--info-block-primary-font-color-with-bg);
        }
      }
    }

    .bg-alert-secondary {
      color: var(--info-block-secondary-font-color);
      border-color: var(--info-block-secondary-color);
      .draftjs-buttons a {
        background-color: var(--info-block-secondary-color);
        &:hover {
          background-color: var(--info-block-secondary-color-hover);
        }
      }
      svg.icon {
        fill: var(--info-block-secondary-icon-color);
      }
      &.bg-color-true {
        background-color: var(--info-block-secondary-color);
      }
      &.bg-color-true {
        color: var(--info-block-secondary-font-color-with-bg);
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        span,
        p,
        .public-DraftEditorPlaceholder-inner {
          color: var(--info-block-secondary-font-color-with-bg);
        }
        svg.icon {
          fill: var(--info-block-secondary-font-color-with-bg);
          color: var(--info-block-secondary-font-color-with-bg);
        }
      }
    }

    .bg-alert-tertiary {
      border-color: var(--info-block-tertiary-color);
      color: var(--info-block-tertiary-font-color);
      .draftjs-buttons a {
        background-color: var(--info-block-tertiary-color);
        color: var(--info-block-tertiary-font-color);
        &:hover {
          background-color: var(--info-block-tertiary-color-hover);
        }
      }
      svg.icon {
        fill: var(--info-block-tertiary-icon-color);
      }
      &.bg-color-true {
        background-color: var(--info-block-tertiary-color);
        color: var(--info-block-tertiary-font-color-with-bg);
        svg.icon,
        .public-DraftEditorPlaceholder-inner,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        span,
        p {
          color: var(--info-block-tertiary-font-color-with-bg);
          fill: var(--info-block-tertiary-font-color-with-bg);
        }
      }
    }

    p {
      margin: 0;
    }

    .image-col {
      text-align: center;
    }

    div + h3,
    p + h3,
    h3 + div,
    h3 + p {
      margin-top: 1.33rem;
    }

    div + h2,
    p + h2,
    h2 + div,
    h2 + p {
      margin-top: 1rem;
    }
  }
}

//SIDEBAR COLORS:
//(By default non sono necessarie, ma devono essere customizzate se cambiano i colori)
/*
body.cms-ui {
  .infoblock {
    .color-list-widget {
      .ui.circular.button.primary {
        background-color: $primary;
        &:hover {
          background-color: darken($primary, 10%);
        }
      }

      .ui.circular.button.secondary {
        background-color: $secondary;
        &:hover {
          background-color: darken($secondary, 10%);
        }
      }
      .ui.circular.button.tertiary {
        background-color: $tertiary;
        &:hover {
          background-color: darken($tertiary, 10%);
        }
      }
    }
  }
}
*/
