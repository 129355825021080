.block.gridBlock {
  .full-width {
    all: unset;
  }
  .block.listing {
    .full-width,
    &.full-width {
      right: unset;
      left: unset;
      width: auto !important;
      margin-right: unset !important;
      margin-left: unset !important;
    }

    .container {
      width: auto !important;
    }
    &.simpleCard,
    &.attachmentCardTemplate {
      .card-teaser-block-3 {
        > .card-teaser {
          flex: 0 0 49%; //invece di 3 elementi per riga, ne mostro due perchè c'è poco spazio
        }
      }
    }

    &.cardWithImageTemplate,
    &.ribbonCardTemplate,
    &.completeBlockLinksTemplate {
      .col-lg-4,
      .col-xl-4,
      .col-lg-3 {
        flex: 0 0 50%; //invece di 3 elementi per riga, ne mostro due perchè c'è poco spazio
        max-width: 50%;
      }
    }

    &.cardSlideUpTextTemplate,
    &.quaresImageTemplate {
      .grid {
        grid-template-columns: 1fr 1fr;
      }
    }

    &.bandiInEvidenceTemplate {
      .bandi-in-evidence-cards-wrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
    &.inEvidenceTemplate {
      .in-evidence-cards-wrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
    &.smallBlockLinksTemplate {
      .col-lg-2 {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
      }
    }
  }
}
